export const shopRoutes = [
    {
        path: '/bakery',
        load: () => import(/* webpackChunkName: 'restaurant' */ './shop/home')
    },
    {
        path: '/bakery/signup',
        load: () => import(/* webpackChunkName: 'restaurant-signup' */ './shop/Signup')
    },
    {
        path: '/bakery/dashboard',
        load: () => import(/* webpackChunkName: 'restaurant' */ './shop/Dashboard')
    },
    {
        path: '/bakery/login',
        load: () => import(/* webpackChunkName: 'login' */ './shop/login')
    },
    {
        path: '/bakery/edit-profile',
        load: () => import(/* webpackChunkName: 'login' */ './shop/EditProfile')
    },
    {
        path: '/bakery/document',
        load: () => import(/* webpackChunkName: 'login' */ './shop/Document')
    },
    {
        path: '/bakery/password',
        load: () => import(/* webpackChunkName: 'restaurant-password' */ './shop/changePassword')
    },
    {
        path: '/bakery/payout',
        load: () => import(/* webpackChunkName: 'restaurant-password' */ './shop/Payout/PayoutList')
    },
    {
        path: '/bakery/addPayout',
        load: () => import(/* webpackChunkName: 'restaurant-password' */ './shop/Payout/AddPayout')
    },
    {
        path: '/bakery/payout/failure',
        load: () => import(/* webpackChunkName: 'restaurant-password' */ './shop/Payout/FailedPayout')
    },
    {
        path: '/bakery/operating-hours',
        load: () => import(/* webpackChunkName: 'restaurant-operation-hours' */ './shop/operationHours')
    },
    {
        path: '/bakery/menu',
        load: () => import(/* webpackChunkName: 'restaurant-menu' */ './shop/category')
    },
    {
        path: '/bakery/menu/add',
        load: () => import(/* webpackChunkName: 'restaurant-menu-add' */ './shop/addMenu')
    },
    {
        path: '/bakery/menu/edit/:id',
        load: () => import(/* webpackChunkName: 'restaurant-menu-add' */ './shop/editMenu')
    },
    {
        path: '/bakery/menu/:id/item',
        load: () => import(/* webpackChunkName: 'restaurant-menu-add' */ './shop/item')
    },
    {
        path: '/bakery/preparation-time',
        load: () => import(/* webpackChunkName: 'restaurant-preparation-time' */ './shop/preparationTime')
    },
    {
        path: '/bakery/order-management/:type',
        load: () => import(/* webpackChunkName: 'restaurant-preparation-time' */ './shop/orderManageContainer')
    },
    {
        path: '/bakery/order-details/:type/:id',
        load: () => import(/* webpackChunkName: 'restaurant-preparation-time' */ './shop/orderDetails')
    },
    {
        path: '/bakery/transaction/:type',
        load: () => import(/* webpackChunkName: 'restaurant-preparation-time' */ './shop/transaction')
    },
    {
        path: '/password/verification',
        load: () => import('./shop/changePasswordUsingToken/')
    },
    {
        path: '/bakery/menu/modifier-groups',
        load: () => import(/* webpackChunkName: 'restaurant-modifiergroups' */ './shop/modifierGroups')
    },
    {
        path: '/bakery/menu/modifier-groups/add',
        load: () => import(/* webpackChunkName: 'restaurant-modifiergroups' */ './shop/addModifierGroups')
    },
    {
        path: '/bakery/menu/modifier-groups/edit/:id',
        load: () => import(/* webpackChunkName: 'restaurant-modifiergroups' */ './shop/editModifierGroups')
    },
    {
        path: '/bakery/menu/:menuId/item/:subMenuId/add',
        load: () => import(/* webpackChunkName: 'restaurant-addfooditem' */ './shop/addFoodItem')
    },
    {
        path: '/bakery/menu/:menuId/item/:subMenuId/edit/:id',
        load: () => import(/* webpackChunkName: 'restaurant-addfooditem' */ './shop/editFoodItem')
    },
];